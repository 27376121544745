import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Manateehero from "../components/hero/Manateehero";
import { FaSistrix } from "react-icons/fa";

function Manatee() {
  return (
    <Layout>
      <Manateehero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>MANATEE COUNTY</h3>
            <p>
              Manatee County. Established in 1855 and named after the beloved
              and gentle Sea Cow. Boasting one of the souths finest sugar
              plantations “Gamble Plantation”, a popular tourist attraction for
              the history buff.
            </p>
            <p>
              Manatee county encourages business growth and community
              prosperity. Manatee County's top industries are tourism and
              agriculture. Tomatoes, strawberries and peppers are their
              highest-grossing crops. Bealls Department Store is headquartered
              in Bradenton. Tropicana is one of the top employers in the County.
            </p>
            <p>
              They also have an amazing waterfront, for a special treat visit
              Anna Maria Island, this quaint community has wonderful beaches,
              shopping and restaurants and will make a wonderful day trip!
            </p>
            <p>
              And then there Holmes Beach, Bradenton Beach right on the Gulf of
              Mexico, and the causeway out to the beaches with calm
              inter-coastal waters, offer a multitude of outdoor activities!
            </p>
            <p>
              Port Manatee is one of Florida’s largest deepwater seaports,
              handling approximately nine million tons of cargo each year.
              Located in the eastern Gulf of Mexico at the entrance to Tampa
              Bay, Port Manatee is the closest U.S. seaport to the Panama Canal,
              providing shippers with speedy access to Pacific Rim markets.
            </p>
            <p>
              As far as housing goes, Manatee County also has some of the nicest
              new housing developments around! Quality builders and quality
              homes are filling new communities for young families and retirees.
              The whole area is buzzing with new construction and wonderful new
              housing opportunities for all. They also have first class schools
              and hospitals. Lakewood Ranch is one of the newer areas full of
              new communities as well as the more established areas of Palmetto,
              Ellenton, Tiera Ceia, Palma Sola, Oneco, and the City of
              Bradenton. We have inspected home in all of these cities and look
              forward to inspecting yours!
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 403,253 (2019 census)
              </li>
              <li>
                <FaSistrix /> Founded: January 9, 1855
              </li>
              <li>
                <FaSistrix /> County Seat: Bradenton
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Manatee County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Bradenton
              </li>
              <li>
                <FaSistrix /> Lakewood Ranch
              </li>
              <li>
                <FaSistrix /> Ellenton
              </li>
              <li>
                <FaSistrix /> Palmetto
              </li>
              <li>
                <FaSistrix /> Tierra Ceia
              </li>
              <li>
                <FaSistrix /> Anna Maria Island & Holmes Beach
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/e575f849defd57b715987d3fc0d37ab8/e42e0/manateemap.jpg"
              target="blank"
            >
              <StaticImage src="../images/manateemap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Manatee;
